{
  "short_name": "Future Sched",
  "name": "Future Schedule",
  "icons": [
    {
      "src": "favicon.png",
      "sizes": "32x32",
      "type": "image/png"
    },
    {
      "src": "favicon.png",
      "type": "image/png",
      "sizes": "32x32"
    }
  ],
  "start_url": ".",
  "display": "standalone",
  "theme_color": "#000000",
  "background_color": "#ffffff"
}
